<template>
  <div>
    <div class="d-flex justify-content-center align-items-center align-self-md-center">
      <img
        alt="Payment Gateway"
        :src="cardknox"
      >
      <h3 class="text-center font-weight-bolder mb-1 ml-2">
        <b-skeleton
          height="40px"
          width="250px"
          style="border-radius: 5px"
        />
      </h3>
    </div>
    <p class="font-weight-bolder font-medium-5">
      {{ $t('Server Settings') }}
    </p>
    <b-form>
      <b-row>
        <b-col
          v-for="key in ['endpoint', 'is_active']"
          :key="key"
          md="6"
        >
          <span
            class="d-block"
            style="margin-bottom: 5px"
          >{{ fields[key].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="key in ['Field 1', 'Field 2']"
          :key="key"
          md="6"
        >
          <span
            class="d-block"
            style="margin-bottom: 5px"
          >{{ key }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import {
  BRow, BSkeleton, BCol, BForm,
} from 'bootstrap-vue'
import cardknox from '@/assets/images/integrations/cardnox.svg'
import config from './cardnoxConfig'

export default {
  name: 'Skeleton',
  components: {
    BForm,
    BCol,
    BRow,
    BSkeleton,
  },
  data() {
    return {
      cardknox,
    }
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
