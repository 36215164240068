<template>
  <skeleton v-if="isLoading" />
  <div v-else>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="paymentGateway.id"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        icon="LPenIcon"
        class="cursor-pointer border-dotted featherIcon"
        size="30"
        style="padding: 4px"
        @click="isEditAble"
      />
    </div>
    <div class="d-flex justify-content-center align-items-center align-self-md-center">
      <img
        alt="Payment Gateway"
        :src="cardknox"
      >
      <h3 class="text-center font-weight-bolder mb-1 ml-1">
        {{ paymentGateway.name }}
      </h3>
    </div>
    <p class="font-weight-bolder font-medium-5">
      {{ $t('Server Settings') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="formCardknox"
      >
        <b-row>
          <b-col
            v-for="key in ['endpoint', 'is_active']"
            :key="key"
            md="6"
          >
            <component
              :is="fields[key].type"
              v-model="paymentGateway[key]"
              v-bind="getProps(key)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="(option, index) in paymentGateway.options"
            :key="option.id"
            md="6"
          >
            <component
              :is="inputOptions.type"
              v-model="paymentGateway.options[index].value"
              :field="getFieldProps(option.key)"
              :name="option.key"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({name: 'settings-general-integrations'})"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="resetForm"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import {
  BButton, BCol, BRow, BForm, VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import Skeleton from '@/views/settings/general/integrations/view/cardknox/Skeleton.vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from './cardnoxConfig'
import cardknox from '../../../../../../assets/images/integrations/cardnox.svg'

export default {
  name: 'Cardknox',
  components: {
    ErrorAlert,
    Skeleton,
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationObserver,
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
  },
  data() {
    return {
      cardknox,
      inputOptions: { type: 'l-text-input' },
      isLoading: true,
      isEdit: true,
      error: {},
      onSubmit: false,
    }
  },

  computed: {
    isFormChanged() {
      return JSON.stringify(this.paymentGateway) !== this.cardknoxFormClone
    },
    cardknoxFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].cardknoxFormClone
    },
    paymentGateway() {
      return this.$store.state[this.MODULE_NAME_PAYMENT_GATEWAYS].paymentGatewayForm
    },
    getFieldProps() {
      return key => ({
        type: 'l-text-input',
        label: key,
      })
    },
  },

  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/get`, this.$route.params.id).then(response => {
      const { data } = response.data
      this.$store.commit(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/SET_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CARDKNOX_FORM_CLONE`, data)
      this.isLoading = false
    }).catch(error => {
      console.error('Error fetching payment gateways:', error)
    })
  },

  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    isEditAble() {
      // eslint-disable-next-line no-return-assign
      return this.isEdit = !this.isEdit
    },
    resetForm() {
      const data = JSON.parse(this.cardknoxFormClone)
      this.$store.commit(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/SET_FORM`, data)
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.formCardknox.validate()
        .then(success => {
          if (success) {
            this.isLoading = true

            const data = this.paymentGateway
            const optionsObject = data.options.reduce((acc, option) => {
              acc[option.key] = option.value
              return acc
            }, {})

            const optionsString = JSON.stringify(optionsObject)

            const gatewayForm = this.mappingFields(Object.keys(this.fields), data, {
              id: data.id ?? null,
              endpoint: data.endpoint,
              is_active: data.is_active ? 1 : 0,
              options: optionsString,
            })

            this.sendNotification(this, gatewayForm, `${this.MODULE_NAME_PAYMENT_GATEWAYS}/${this.paymentGateway.id ? 'update' : ''}`)
              .then(response => {
                this.$store.commit(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/SET_FORM`, response.data.data)
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CARDKNOX_FORM_CLONE`, response.data.data)
                // this.$emit('refetchData')
              })
              .catch(err => {
                // this.error = err.response?.data
                this.error = true
                this.errorNotification(this, err)
              }).finally(() => {
                // this.resetForm()
                this.isLoading = false
              })
          } else {
            scrollToError(this, this.$refs.formCardknox)
          }
        })
    },
    created() {
      this.$store.commit(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/SET_FORM`, {})
    },
  },
  setup() {
    const MODULE_NAME_PAYMENT_GATEWAYS = 'payment-gateways'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME_PAYMENT_GATEWAYS,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style scoped>

</style>
