import { TEXT_INPUT, CHECKBOX_LEF } from '@/views/components/DynamicForm/constants'

export default function config() {
  // const ACCESS_ABILITY_RATES = { action: 'change', subject: 'Rates' }

  const fields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      placeholder: 'Type...',
    },
    service: {
      type: TEXT_INPUT,
      label: 'Service',
      placeholder: 'Type...',
    },
    endpoint: {
      type: TEXT_INPUT,
      label: 'Endpoint',
      placeholder: 'Type...',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Status',
    },
    // status: {
    //   type: CHECKBOX,
    //   label: 'Status',
    // },
  }
  return {
    fields,
    // ACCESS_ABILITY_RATES,
  }
}
